import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WhoWeAre from "../components/who-we-are";

const WhoWeArePage = () => (
  <Layout>
    <SEO title="Who We Are" />
    <WhoWeAre />
  </Layout>
);

export default WhoWeArePage;
