import { useStaticQuery, graphql } from "gatsby";

export const useData = () => {
  const data = useStaticQuery(graphql`
    fragment WhoWeArePhotos on File {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      orthodontist: file(
        relativePath: { eq: "about/thum-orthodontist-1.png" }
      ) {
        ...WhoWeArePhotos
      }
      team: file(relativePath: { eq: "about/thum-reception.png" }) {
        ...WhoWeArePhotos
      }
      practice: file(relativePath: { eq: "about/thum-dental.png" }) {
        ...WhoWeArePhotos
      }
    }
  `);

  const orthDes =
    "Adam Wallace is a specialist orthodontist who has had more than 17 years of experience in the dental profession. He was born and raised in Arawata, South Gippsland. His mum owned the Quilter’s Barn in Loch and his dad worked with Graham Rowe at Vin Rowe Farm Machinery of which he has had many fond memories as a kid!";
  const teamDes =
    "Our staff aim to provide you with a personalised and enjoyable experience during your orthodontic journey. Our team aims to provide you with clinical excellence by using the latest orthodontic principles and techniques.";
  const practiceDes =
    "We pride ourselves on the ability to combine a warm and welcoming environment with our modern state-of-the-art facilities.";
  const result = [
    {
      id: "orthodontist",
      to: "/orthodontist",
      title: "Our Principal Orthodontist",
      description: orthDes.substring(0, 70) + "...",
      mainImage: data.orthodontist.childImageSharp.fluid,
    },
    {
      id: "team",
      to: "/our-team",
      title: "Our Team",
      description: teamDes.substring(0, 70) + "...",
      mainImage: data.team.childImageSharp.fluid,
    },
    {
      id: "practice",
      to: "/our-practice",
      title: "Our Practice",
      description: practiceDes.substring(0, 70) + "...",
      mainImage: data.practice.childImageSharp.fluid,
    },
  ];

  return result;
};
